(function () {
  'use strict';

  class EditStudentsCtrl {
    constructor($mdDialog, EditStudents, User) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.User = User;
      vm.user = EditStudents.getObj();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.User.userEdit(vm.user)
          .then((data)=> {
            vm.hide(data);
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:EditStudentsCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('EditStudentsCtrl', EditStudentsCtrl);
}());
